.app {
  height: 100vh;

  .sections {
    width: 100%;
    height: calc(100vh - 70px); // 100% - 70px is the height and position of the section.
    background-color: rgb(150, 150, 150);
    position: relative; // for the position of the section.
    top: 70px; // Start after 70px from the top.
    scroll-behavior: smooth; // Smooth scrolling.
    scroll-snap-type: y mandatory; // allows snaping while scrolling.
    scrollbar-width: none; // removes the scrollbar. For Firefox.
    &::-webkit-scrollbar {
      // Removes scrollbar for all other Browsers.
      display: none;
    }

    > * {
      width: 100vw;
      height: calc(100vh - 70px);
      scroll-snap-align: start;
    }
  }
}
