$mainColor: #212121;
// main color for the site

$width: 768px;

// for mobile
@mixin mobile {
	@media (max-width: #{$width}) {
		@content;
	}
}
