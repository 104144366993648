@import '../../global.scss';

.portfolioList {
	font-size: 16px;
	margin-right: 50px;
	padding: 8px;
	border-radius: 4px;
	cursor: pointer;

	@include mobile {
		margin-right: 20px;
	}

	&.active {
		background-color: $mainColor;
		color: white;
	}
}
