@import '../../global.scss';

.intro {
	background-color: #f4f4f4;
	display: flex;

	@include mobile {
		flex-direction: column;
		align-items: center;
	}

	.left {
		flex: 0.5;
		overflow: hidden;

		.imgContainer {
			// main photo area.
			width: 1100px;
			height: 1100px;
			background-color: lightseagreen;
			border-radius: 50%;
			display: flex;
			align-items: center;
			float: right;

			@include mobile {
				align-items: flex-start;
			}

			img {
				width: 100%;

				@include mobile {
					height: 50vh;
					width: 60%;
					margin-left: 225px;
				}
			}
		}
	}

	.right {
		flex: 0.5;
		position: relative;

		.wrapper {
			width: 100%;
			height: 100%;
			padding-left: 50px;
			display: flex;
			flex-direction: column;
			justify-content: center;

			@include mobile {
				padding-left: 0;
				align-items: center;
			}

			h1 {
				font-size: 60px;
				margin: 10px 0;

				@include mobile {
					font-size: 40px;
				}
			}
			h2 {
				font-size: 35px;
			}
			h3 {
				font-size: 30px;

				@include mobile {
					font-size: 20px;
				}

				span {
					font-size: inherit;
					color: lightseagreen;
				}

				.ityped-cursor {
					animation: blink 1s infinite;
				}

				@keyframes blink {
					50% {
						opacity: 1;
					}
					100% {
						opacity: 0;
					}
				}
			}
		}
	}
	a {
		position: absolute;
		bottom: 10px;
		left: 40%;

		#arrowDown {
			font-size: 4vw;
			color: #212121;
			animation: arrowBlink 2s infinite;
		}

		@keyframes arrowBlink {
			100% {
				opacity: 0;
			}
		}
	}
}
