@import '../../global.scss';

.portfolio {
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	h1 { // titleName Michael O'Reilly
		font-size: 2.5rem;
		padding: 16px;

		@include mobile {
			font-size: 20px;
		}
	}

	ul {
		margin: 10px;
		padding: 0;
		list-style: none;
		display: flex;
		padding: 8px;

		@include mobile {
			margin: 10px, 0;
			flex-wrap: wrap;
			justify-content: center;
		}
	}


	.portfolioContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	.container {
		width: 60%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		@include mobile {
			width: 100%;
		}

		.item {
			width: 8rem;
			// height: 10rem;
			border-radius: 8px;
			border: 1px solid lightgrey;
			margin: 10px 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: white;
			position: relative;
			transition: all 0.25s ease;
			// cursor: pointer;

			@include mobile {
				width: 130px;
				height: 100px;
			}

			h3 {
				position: absolute;
				font-size: 1.5em;
			}
			img {
				width: 12rem;
				// height: 100%;
				object-fit: cover;
				z-index: 1;
				
			}
			&:hover {
				transform: scale(1.1);
				// cursor: pointer;
				
				
			}
		}
	}
}
