@import '../../global.scss';


.projects {
	background-color: #f4f4f4;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;

	#projectsTitleArea {
		margin: 20px;
	}
	
	.arrow {
		height: 80px;
		position: absolute;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;

		@include mobile {
			display: none;
		}

		&.left {
			left: 15vw;
			
		}

		&.right {
			right: 15vw;
		}
	}

	.slider {
		height: 600px; // height of the slider
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 0;
		transition: all 1s ease-out;

		@include mobile {
			height: 100vh;
			flex-direction: column;
			justify-content: center;
		}

		.sectionContainer {
			width: 100vw;
			height: 60vh;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
			

			h1{
				font-size: 2.5rem;
				color: #333;
				text-align: center;
				margin: 5px;
				padding: 5px;
			}

			h2 {
				font-size: 2rem;
				color: #333;
				text-align: center;
				margin: 5px;
				padding: 5px;
			}
			
			#description {
				width: 45%;
			}

			p {
				font-size: 1.25rem;
				color: #333;
				text-align: left;
				margin: 10px;
				padding: 10px;
			}

			#imageContainer {
				width: 45vw;
				height: 45vh;
				background-size: cover;
				background-position: center;
			}

			img {
				width: 100%;
				height: 100%;
				border-radius: 2%;
				margin: 5px;
				padding: 5px;
				}
			}
		}

		.projectsCenterArea {
			flex: 1;
			height: 100%;
			
			img {
				width: 50vw;
				height: 50vh;
			}

			@include mobile {
				height: 75%;
				width: 75%;
				margin: 0;
			}
		}
	}

