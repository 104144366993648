@import '../../global.scss';

.contact {
	background-color: lightseagreen;
	display: flex;

	@include mobile {
		flex-direction: column;
	}

	.left {
		flex: 1;
		overflow: hidden;

		img {
			height: 100%;
		}
	}
	.right {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		h2 {
			font-size: 30px;
			margin-bottom: 20px;
		}

		#contactMe {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-bottom: 40px;
			font-size: 1.5em;
			color: #fff;
			
		}

		#myEmail {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-size: 2.5em;
			color: #fff;
		}
	}
}
